import { render, staticRenderFns } from "./VotedSongs.vue?vue&type=template&id=5ac55503"
import script from "./VotedSongs.vue?vue&type=script&lang=js"
export * from "./VotedSongs.vue?vue&type=script&lang=js"
import style0 from "./VotedSongs.vue?vue&type=style&index=0&id=5ac55503&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/vue-painel/vue-painel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ac55503')) {
      api.createRecord('5ac55503', component.options)
    } else {
      api.reload('5ac55503', component.options)
    }
    module.hot.accept("./VotedSongs.vue?vue&type=template&id=5ac55503", function () {
      api.rerender('5ac55503', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Dashboard/VotedSongs.vue"
export default component.exports