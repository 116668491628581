import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=57ac5493"
import script from "./Content.vue?vue&type=script&lang=js"
export * from "./Content.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/vue-painel/vue-painel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57ac5493')) {
      api.createRecord('57ac5493', component.options)
    } else {
      api.reload('57ac5493', component.options)
    }
    module.hot.accept("./Content.vue?vue&type=template&id=57ac5493", function () {
      api.rerender('57ac5493', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Dashboard/Layout/Content.vue"
export default component.exports